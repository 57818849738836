<template>
  <page-container>
    <viewer :images="images.map(img => img.src)" :options="options">
      <div class="d-flex flex-wrap justify-space-around">
        <div v-for="img in images" :key="img.src" class="ma-1 pa-0 text-center">
          <img :src="require('@/assets/img/site/gallery/' + img.src)" :alt="img.desc" style="cursor: pointer;" :style="{
            maxWidth: $vuetify.breakpoint.mdAndUp ? '260px' : '180px',
            height: $vuetify.breakpoint.mdAndUp ? '160px' : '120px'
          }" />
        </div>
      </div>
    </viewer>
  </page-container>
</template>

<script>
// https://itzone.com.vn/en/article/display-and-display-images-in-vuejs-application-with-v-viewer/

import images from './images.js'
import { shuffle } from '@/utils/methods.js'

export default {
  name: 'App',
  data () {
    return {
      options: {
        rotatable: false,
        title: false
      },
      images: shuffle(images)
    }
  },
  components: {
    PageContainer: () => import('@/components/reusable/PageContainer')
  }
}
</script>

<style>
.viewer-play {
  margin: -3px 12px !important;
}

.viewer-flip-horizontal,
.viewer-flip-vertical,
.viewer-reset,
.viewer-one-to-one,
.viewer-zoom-in,
.viewer-zoom-out {
  display: none;
}

.viewer-backdrop {
  background-color: rgba(0, 0, 0, 0.9);
}

.viewer-close {
  right: -30px;
  top: -30px;
}
</style>
