const context = require.context('@/assets/img/site/gallery/', false, /\.(png|jpg|jpeg)$/)
const images = context.keys().map(context)
  .map(item => {
    let imgFile = item.replace('/img/', '')
    const nameSplit = imgFile.split('.')
    imgFile = `${nameSplit[0]}.${nameSplit[2]}`
    return {
      src: imgFile,
      desc: nameSplit[0]
    }
  })

export default images
